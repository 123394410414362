import React, {Component} from 'react'
import Footer from './compt_of_home/Footer';
import Topbar from './compt_of_home/Topbar';

import API from '../axios/api';

import img_mockup_laptop from '../assets/assets/img/header_passpor.png';

class Verifikasi extends Component{

    componentDidMount(){
        const getMyIv = this.props.match.params.iv;
        const getMyContent = this.props.match.params.content
        API.post('/verification',{
            iv: getMyIv,
            content: getMyContent
        })
        .then(res => {
            console.log(res.data.status);
            if(res.data.status === 200){
                // MAKE ACTION SUCCESS
                console.log("success");
            }
        })
        .catch(err => console.error());
    }

    render(){
        return(
            <body id="page-top">
                <Topbar/>
                <header class="masthead" id="header">
                    <div class="container px-5">
                        <div class="row gx-5">
                            <div class="col-lg-12 text-center">
                                <img src={img_mockup_laptop} alt="..." width="30%" />
                            </div>
                        </div>
                    </div>
                </header>
                <Footer/>
            </body>
        )
    }
}

export default Verifikasi;