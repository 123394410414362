import { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Home from './component/Home';

import $ from 'jquery';
import Verifikasi from "./component/Verifikasi";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

class App extends Component {
  render(){
    return (
      <BrowserRouter>
        <Route path='/' exact component={Home} />
        <Route path='/verifikasi/:iv/:content' component={Verifikasi}/>
      </BrowserRouter>
    )
  }
}

export default App;
