import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component{
    render(){
        return(
            <footer class="bg-black text-center py-5">
                <div class="container px-5">
                    <div class="small" id="txt_foot">
                        <div class="mb-2">&copy; Passport Warning 2021. All Rights Reserved.</div>
                        <Link to="/Faq"><span class="link_footer">Privacy</span></Link>
                        <span class="mx-1">&middot;</span>
                        <Link to="/Faq"><span class="link_footer">Terms</span></Link>
                        <span class="mx-1">&middot;</span>
                        <Link to="/Faq"><span class="link_footer">FAQ</span></Link>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;