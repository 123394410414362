import React, {Component} from 'react';

class Topbar extends Component{
    render(){
        return(
            <nav class="navbar navbar-expand-lg navbar-dark fixed-top shadow-sm" id="mainNav">
                <div class="container px-5">
                    <a class="navbar-brand fw-bold" href="#header">
                    Paspor Warning
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    Menu<i class="bi-list"></i>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav ms-auto me-4 my-3 my-lg-0">
                        <li class="nav-item"><a class="nav-link me-lg-3" href="#registerpaspor" id="text_footer">Download Apps</a></li>
                        {/* <li class="nav-item"><a class="nav-link me-lg-3" href="#download" id="text_footer">Download</a></li> */}
                    </ul>
                    <a class="nav-link me-lg-3" href="#registerpaspor" id="text_footer">
                        <button class="btn btn-warning rounded-pill px-3 mb-2 mb-lg-0" data-bs-target="#registerpaspor">
                            <span class="d-flex align-items-center">
                                <i class="bi-chat-text-fill me-2"></i>
                                <span class="small" id="text_footer">Register Passport</span>
                            </span>
                        </button>
                    </a>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Topbar;