import React, {Component} from 'react';

import img_mockup_laptop from '../../assets/assets/img/header_passpor.png';

class Header extends Component{
    render(){
        return(
            <header class="masthead" id="header">
                <div class="container px-5">
                    <div class="row gx-5 align-items-center">
                        <div class="col-lg-6">

                        <div class="mb-5 mb-lg-0 text-center text-lg-start">
                            <h1 class="display-1 lh-1 mb-3">Title Untuk Paspor Warning</h1>
                            <div id="subtitlenya">Deskripsi penjelasan tentang sistem informasi Paspor Warning</div>
                            <a class="btn btn-outline-dark py-3 px-4 rounded-pill" href="#download">Dapatkan Penawaran</a>
                        </div>

                        </div>
                        <div class="col-lg-6">
                            <img src={img_mockup_laptop} alt="..." width="100%" />
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default Header;