import { Component } from "react";
import $ from 'jquery';

import Topbar from "./compt_of_home/Topbar";
import Header from "./compt_of_home/Header";
import Registerpaspor from "./compt_of_home/Registerpaspor";
import Footer from "./compt_of_home/Footer";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

class Home extends Component{
    render(){
        return(
            <body id="page-top">
                <Topbar/>
                <Header/>
                <Registerpaspor/>
                <Footer/>
            </body>
        )
    }
}

export default Home;