import React, { useState } from 'react';
import API from '../../axios/api';
import API_WHATSAPP from '../../axios/api_whatsapp'

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Moment from 'moment';

import img_mockup_hp from '../../assets/assets/img/img_passpor.png';
import img_loader from '../../assets/assets/loader2.gif';

function Registerpaspor(){

    // const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }
    const [date, setDate] = useState(new Date());
    const [dateIssue, setDateIssue] = useState(new Date());
    const [dateExpiry, setDateExpiry] = useState(new Date());

    const [data, setData] = useState({
        nama: "",
        tempat_lahir: "",
        tanggal_lahir: "",
        phone: "",
        email: "",
        no_passport: "",
        date_issue: "",
        date_expiry: "",
        register_no: "",
        issuing_office: ""
    })

    const [loading, setLoading] = useState(false);


    function submit(e) {
        setLoading(true);
        e.preventDefault();
        API.post('/user_passport',{
            nama: data.nama,
            tempat_lahir: data.tempat_lahir,
            tanggal_lahir: Moment(date).format('Do MMMM yyyy'),
            phone: data.phone,
            email: data.email,
            no_passport: data.no_passport,
            date_issue: Moment(dateIssue).format('Do MMMM yyyy'),
            date_expiry: Moment(dateExpiry).format('Do MMMM yyyy'),
            register_no: data.register_no,
            issuing_office: data.issuing_office
        })
        .then(res => {
            console.log(res.data.status);
            if(res.data.status === 200){
                resetInputField();
                setLoading(false);
            }
        })
        .catch(err => console.error());
        
        var jarak = Moment(`${Moment(dateExpiry).format('yyyy MMMM Do')}`, "yyyy MMMM Do").fromNow();

        console.log(`BERAPA TAHUN LAGI ${jarak}`);
        
        API.post('/emailer', {
            to: data.email,
            subject: 'Verifikasi Email',
            nama_lengkap: data.nama
        })
        .then(res => {
            console.log(res.data.status);
        })
        .catch(err => console.error());

        API_WHATSAPP.post('/send-message', {
            number: data.phone,
            message: `Selamat datang, selamat bergabung ${data.nama}`
        })
        .then(res => {
            console.log(res.data.status);
        })
        .catch(err => console.error());
    }

    function handle(e) {
        const newData={...data}
        newData[e.target.id] = e.target.value
        setData(newData)
        console.log(newData)
    }

    const resetInputField = () => {
        setData("");
    };

        return(
            <section id="registerpaspor">
                <div class="container px-5">
                    <div class="grid-container-reg align-items-center">
                        
                        <div class="text-center">
                            <img src={img_mockup_hp} alt="..." width="90%" />
                        </div>
                        <div>
                                {
                                        loading ? 
                                        (
                                            <div class="text-center">
                                                <img src={img_loader} alt="..."/>
                                            </div>
                                        ) : 
                                        (
                                            <form id="contactForm" data-sb-form-api-token="API_TOKEN" onSubmit={(e)=> submit(e)}>
                                                <div class="d-grid">
                                                    <h3 class="font-alt text-center">Register Passport</h3>
                                                    <br></br>
                                                </div>

                                                <div class="form-floating mb-3">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text" id="iconform"><i class="bi bi-person-fill"></i></div>
                                                        </div>
                                                        <input name="nama" onChange={(e)=>handle(e)} id="nama" value={data.nama} type="text" class="form-control" placeholder="Full Name" required/>
                                                    </div>
                                                </div>
                                                <div class="form-floating mb-3" id="container_form_row">
                                                    <div class="input-group mb-2">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text" id="iconform"><i class="bi bi-geo-alt-fill"></i></div>
                                                        </div>
                                                        <input name="tempat_lahir" onChange={(e)=>handle(e)} id="tempat_lahir" value={data.tempat_lahir}  type="text" class="form-control" placeholder="Place of birth" required/>
                                                    </div>
                                                    <div class="input-group mb-2">
                                                        <div id="text_label">&nbsp; Date of birth &nbsp;</div>
                                                        <DatePicker 
                                                            required
                                                            dateFormat="d MMMM yyyy"
                                                            selected={date} 
                                                            onChange={date => setDate(date)} 
                                                            className="form-control"
                                                            id="tanggal_lahir"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="form-floating mb-3">
                                                    <div class="input-group mb-2">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text" id="iconform"><i class="bi bi-telephone-fill"></i></div>
                                                        </div>
                                                        <input type="tel" pattern="[0-9]{4}-[0-9]{4}-[0-9]{4}" name="phone" onChange={(e)=>handle(e)} id="phone" value={data.phone} class="form-control" placeholder="Phone" required/>
                                                    </div>
                                                </div>
                                                <div class="form-floating mb-3">
                                                    <div class="input-group mb-2">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text" id="iconform"><i class="bi bi-envelope-fill"></i></div>
                                                        </div>
                                                        <input type="email" name="email" onChange={(e)=>handle(e)} id="email" value={data.email} class="form-control" placeholder="Email" required/>
                                                    </div>
                                                </div>
                                                <div class="form-floating mb-3">
                                                    <div class="input-group mb-2">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text" id="iconform"><i class="bi bi-person-badge-fill"></i></div>
                                                        </div>
                                                        <input name="no_passport" onChange={(e)=>handle(e)} id="no_passport" value={data.no_passport} type="text" class="form-control" placeholder="Passport No." required/>
                                                    </div>
                                                </div>
                                                <div class="form-floating mb-3" id="container_form_row">
                                                    <div class="input-group mb-2">
                                                        <div id="text_label">&nbsp; Date of issue &nbsp;</div>
                                                        <DatePicker 
                                                            required
                                                            dateFormat="d MMMM yyyy"
                                                            selected={dateIssue} 
                                                            onChange={dateIssue => setDateIssue(dateIssue)} 
                                                            className="form-control"
                                                            id="date_issue"
                                                        />
                                                    </div>
                                                    <div class="input-group mb-2">
                                                        <div id="text_label">&nbsp; Date of expiry &nbsp;</div>
                                                        <DatePicker 
                                                            required
                                                            dateFormat="d MMMM yyyy"
                                                            selected={dateExpiry} 
                                                            onChange={dateExpiry => setDateExpiry(dateExpiry)} 
                                                            className="form-control"
                                                            id="date_expiry"
                                                        />
                                                    </div>
                                                </div>
                                                
                                                <div class="form-floating mb-3">
                                                    <div class="input-group mb-2">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text" id="iconform"><i class="bi bi-card-checklist"></i></div>
                                                        </div>
                                                        <input name="register_no" onChange={(e)=>handle(e)} id="register_no" value={data.register_no} type="text" class="form-control" placeholder="Reg. No" required/>
                                                    </div>
                                                </div>
                                                <div class="form-floating mb-3">
                                                    <div class="input-group mb-2">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text" id="iconform"><i class="bi bi-geo-alt-fill"></i></div>
                                                        </div>
                                                        <input name="issuing_office" onChange={(e)=>handle(e)} id="issuing_office" value={data.issuing_office} type="text" class="form-control" placeholder="Issuing office" required/>
                                                    </div>
                                                </div>
                                                <div class="d-grid">
                                                    <button class="btn btn-warning rounded-pill btn-lg enable font-alt" id="submitButton" type="submit">
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                        )
                                }
                        </div>
                    </div>
                </div>
            </section>
        )
}

export default Registerpaspor;